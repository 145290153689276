import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import classnames from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";

const VideoCollectionPage = ({ data }) => {
  const collectionItems = data.prismicVideoCollection.data.body[0].items.filter(
    item => item.image.fluid && item.video_embed_iframe_link
  );

  const {
    collection_name,
    body,
    meta_title,
    meta_description
  } = data.prismicVideoCollection.data;

  return (
    <Layout>
      <SEO description={meta_description} title={meta_title} />
      <div className="flex flex-wrap w-full max-w-6xl mx-auto items-center c-gallery">
        {collectionItems.map(
          ({ alt_text, image, image_size, video_embed_iframe_link }, index) => (
            <div
              key={index}
              className={classnames("w-1/2", {
                "p-4": image_size === "full",
                "p-10": image_size === "centered",
                "p-10 pl-1/10 md:pl-1/10": image_size === "right",
                "p-10 pr-1/10 md:pr-1/10": image_size === "left",
                "p-10 pb-1/5 md:pb-1/5": image_size === "up",
                "p-10 pt-1/5 md:pt-1/5": image_size === "down"
              })}
            >
              <Link
                to={`/videos/${video_embed_iframe_link.substr(
                  video_embed_iframe_link.lastIndexOf("/") + 1
                )}`}
                className="w-full block focus:outline-none"
              >
                <Img
                  fluid={image.fluid}
                  className="w-full"
                  alt={image.alt_text ? image.alt_text.text : ""}
                />
              </Link>
            </div>
          )
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query VideoCollectionBySlug($uid: String!) {
    prismicVideoCollection(uid: { eq: $uid }) {
      data {
        meta_description
        meta_title
        collection_name
        body {
          ... on PrismicVideoCollectionBodyImageGallery {
            id
            items {
              video_embed_iframe_link
              image {
                fluid(maxWidth: 1200) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              image_size
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default VideoCollectionPage;
